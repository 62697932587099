<script>
  export default {
    props: ["news"],
    data: function() {
      return {
        media: null,
        id: this.$global.fastID(),
      };
    },
    methods: {
      getMedia: function() {
        const media = [];

        if (this.news.videoNews) {
          const video = { src: this.news.videoNews, type: "video" };
          media.push(video);
        }

        if (this.news.imageNews) {
          const image = { src: this.news.imageNews, type: "image" };
          media.push(image);
        }

        if (media.length) {
          this.media = media;
        }
      },
    },
    mounted: function() {
      this.getMedia();
    },
  };
</script>

<template>
  <div class="news-media" v-if="media">
    <div class="news-media-file" :ref="`cr-${id}`" v-for="(file, index) in media" :key="index">
      <ImageVisor v-if="file.type === 'image'" :file="file" class="image-visor" />
    </div>
  </div>
</template>

<style lang="scss">
  .news-media {
    width: 100%;
    height: 100%;
    &-file {
      @include Flex();
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
</style>
